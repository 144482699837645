import styled, { css } from 'styled-components'

export default styled.div`
    position: relative;

    .feature-bg {
        position: absolute;
        z-index: -1;
        top: -600px;
        left: -50vw;
    }

    .card {
        height: 100%;
        margin: 0 10px;
    }

    
    .image-container {
        img {
            width: 100%;
        }

        margin-top: auto;
    }
`