import Card from "blocks/Card"
import { Container, Row, RowItem } from "blocks/CommonStyles"
import { Title } from "blocks/Typography"
import StyledPartners from './styles'

const Partners: React.FC = () => (
    <StyledPartners className="partners">

        <img src="/static/images/bg.png" className="partner-bg" alt='bg' />
        <Container space="medium">
            <Title align="center">Partners and backers</Title>
        </Container>

        <Row className="list">
            <RowItem className="item">
                <Card>d</Card>
            </RowItem>
            <RowItem className="item">
                <Card>d</Card>
            </RowItem>
            <RowItem className="item">
                <Card>d</Card>
            </RowItem>
            <RowItem className="item">
                <Card>d</Card>
            </RowItem>
        </Row>
    </StyledPartners>
)

export default Partners