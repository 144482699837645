import Card from "blocks/Card"
import { Container } from "blocks/CommonStyles"
import { Text, Title } from "blocks/Typography"
import DescriptionBox from "components/DescriptionBox"
import Carousel from 'react-elastic-carousel'
import StyledTeam from './styles'

const breakPoint = [
    { width: 400, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 4 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
    { width: 1750, itemsToShow: 4 },
  ]

const Team: React.FC = () => (
    <StyledTeam>
        <DescriptionBox
            title="Team"
            secondaryTitle='Metaplug Family'
            id='team'
        >
            <div className="carousel-wrapper">
                <Carousel isRTL={false} itemsToShow={4} enableSwipe={true} disableArrowsOnEnd={true} breakPoints={breakPoint}>
                    <Card className="card" space="x-small">
                        <Container space="xx-small" className="image-container">
                            <img src="/static/images/team/green.svg" alt="team" />
                        </Container>
                        <Container space="xx-small">
                            <Title size="small" color="gold" align="center">MRTUBBY</Title>
                        </Container>
                        <Container space="xx-small">
                            <Text align="center">Her/His Role</Text>
                        </Container>
                    </Card>
                    <Card className="card" space="x-small">
                        <Container space="xx-small" className="image-container">
                            <img src="/static/images/team/yellow.svg" alt="team" />
                        </Container>
                        <Container space="xx-small">
                            <Title size="small" color="gold" align="center">MRTUBBY</Title>
                        </Container>
                        <Container space="xx-small">
                            <Text align="center">Her/His Role</Text>
                        </Container>
                    </Card>
                    <Card className="card" space="x-small">
                        <Container space="xx-small" className="image-container">
                            <img src="/static/images/team/pink.svg" alt="team" />
                        </Container>
                        <Container space="xx-small">
                            <Title size="small" color="gold" align="center">MRTUBBY</Title>
                        </Container>
                        <Container space="xx-small">
                            <Text align="center">Her/His Role</Text>
                        </Container>
                    </Card>
                    <Card className="card" space="x-small">
                        <Container space="xx-small" className="image-container">
                            <img src="/static/images/team/blue.svg" alt="team" />
                        </Container>
                        <Container space="xx-small">
                            <Title size="small" color="gold" align="center">MRTUBBY</Title>
                        </Container>
                        <Container space="xx-small">
                            <Text align="center">Her/His Role</Text>
                        </Container>
                    </Card>
                </Carousel>
            </div>
        </DescriptionBox>
    </StyledTeam>
)

export default Team
