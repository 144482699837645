import StyledHome from './styles'
import Header from 'components/header'
import Card from 'blocks/Card'
import { Row, Container, Divider, RowItem } from 'blocks/CommonStyles'
import { Text, Title } from 'blocks/Typography'
import Button, { ButtonWrapper } from 'blocks/Button'
import DescriptionBox from 'components/DescriptionBox'
import Features from 'components/Features'
import Partners from 'components/Partners'
import Team from 'components/Team'
import Rarity from 'components/Rarity'
import SocialMedia from 'components/SocialMedia'
import Footer from 'components/Footer'
import RoadMap from 'components/RoadMap'
import { ThemeMode } from 'context/ThemeModeProvider'
import { useContext } from 'react'

const Home = () => {
    const { mode } = useContext(ThemeMode)

    return (
        <StyledHome>
            <div className='secondary-head'>
                <div 
                    className='secondary-head__wrapper' 
                    style={{ backgroundImage: 'url(/static/images/secondary-header-background.svg)' }}
                >
                    <Row className='card-wrapper' justify='center' align='center'>
                        <Card space='large'>
                            <Container space='small'>
                                <Title className='title' color='dark' size='large' align='center'>Meet Metaplug</Title>
                            </Container>

                            <Container space='small'>
                                <Text className='text' color='dark' size='large' align='center'>
                                    NFT Collection where anything is possible and all are welcome
                                </Text>
                            </Container>

                            <Container space='small'>
                                <ButtonWrapper justify='center'>
                                    <Button color='light'>Join Discord </Button>
                                    <Button color='outlined'>Merch</Button>
                                </ButtonWrapper>
                            </Container>
                        </Card>
                    </Row>
                </div>
            </div>

            <Divider />

            <DescriptionBox 
                title='About' 
                secondaryTitle='Welcome to the Metaplug'
                buttonText='Join Discord'
                className='about-box'
                imgSrc={`/static/images/about-${mode}.svg`}
                id='about'
            >
                <img src="/static/images/bg.png" className='about-bg' alt="bg" />
                Get Connected MetaPlugs is a collection of 10,000 unique characters based on the underground drug trade from around the world. The characters are split up into 6 ranks (or rarities) ranging from a local street thug all the way up to a Cartel boss. Your character will have special traits generated so that not one is identical to any other, making all 10,000 NFT’s unique to the person who owns them. Are you ready to be a Plug?
            </DescriptionBox>

            <Divider space='medium' />

            <Rarity />

            <Divider space='medium' />

            <RoadMap />

            <Divider space='medium' />

            <Features />

            <Divider space='medium' />

            <Partners />

            <Divider space='medium' />

            <Team />

            <Divider space='medium' />

            <DescriptionBox 
                title='Your NFT' 
                secondaryTitle='How to own an NFT'
                buttonText='Mint'
                imgSrc={`/static/images/your-nft-${mode}.svg`}
                className='nft-box'
            >
                <img src='/static/images/bg.png' alt='bg' className='nft-bg' />
                Having your own MetaPlug will give you as a holder early Information on future projects,along with discounted prices for future mints. Get Early access on future games and Merchandise drops.               
            </DescriptionBox>

        </StyledHome>
    )
}

export default Home