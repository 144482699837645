import styled, { css } from 'styled-components'

export default styled.footer`
    ${({ theme: { space, mode, color, screenSize } }) => css`
        background-position: start center;
        background-position-x: -30vh;
        background-position-y: -300px;
        position: relative;

        .footer-bg {
            position: absolute;
            bottom: 0;
            left: -10vw;
            z-index: -1;
        }
    
        .footer {
            &__main {
                padding: ${space.md};

                @media (max-width: ${screenSize.mobile}) {
                    padding: 0 ${space.md};
                }

                .item {
                    @media (max-width: ${screenSize.mobile}) {
                        margin-right: 0;
                        margin-bottom: ${space.md};
                        flex: 0 0 100%;
                    }
                }

                .text {
                    font-size: 18px;

                    @media (max-width: ${screenSize.mobile}) {
                        text-align: center;
                    }
                }

                .links {
                    padding: 0 ${space.md};
                }

                .small-icon {

                    &:not(&:last-child) {
                        margin-right: ${space.lg};
                    }

                    svg {
                        & > * {
                            fill: ${mode === 'dark' ? color.light : color.black};
                        }
                    }

                }

                .social-footer-container {
                    @media (max-width: ${screenSize.mobile}) {
                        width: 100%;

                        .social-footer {
                            justify-content: center;

                            .small-icon {
                                &:not(&:last-child) {
                                    margin-right: ${space.sm};
                                }
                            }
                        }
                    }
                }
            }
        }

        .title {
            font-size: 22px;
        }

        .copyright {
            padding-bottom: ${space.md};
        }
    `}
`