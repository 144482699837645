import styled, { css } from 'styled-components'

export default styled.div`
    ${({ theme: { space, fs, screenSize, mode, color } }) => css`
        padding: ${space.md};
        position: relative;

        .head {
            margin-bottom: 5em;

            .logo {
                @media (max-width: ${screenSize.mobile}) {
                    display: none;
                }
            }

        }

        .roadmap-bg {
            position: absolute;
            left: 50%;
            top: -300px;
            x-index: -1;
        }

        .roadmap {
            position: relative;

            .line {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                width: 1px;
                height: calc(100% - 250px);
                background: ${mode === 'dark' ? 'linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.5) 100%)' : color.dark };
                
                @media (max-width: ${screenSize.mobile}) {
                    display: none;
                }
            }
        }

        .logo {
            display: flex;
            margin-right: ${space.md};
            
            img {
                border-right: 1px solid #3E3E3E;
            }
        }

        img {
            width: 100%;
            margin-inline: auto;
        }

        .card {
            width: 33vw;
            max-width: 476px;
            min-height: 216px;
            position: relative;
            
            @media (max-width: ${screenSize.mobile}) {
                width: 100%;
                max-width: 100%;
            }

            .card-title {
                font-size: ${fs.sm};
            }

            .connect-line {
                position: absolute;
                display: flex;
                align-items: center;

                @media (max-width: ${screenSize.mobile}) {
                    display: none;
                }

                .inner-line {
                    height: 1px;
                    width: 5.25vw;
                    max-width: 80px;
                    background: ${mode === 'dark' ? 'linear-gradient(95.68deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.5) 100%)' : color.dark };
                    diplay: flex;
                }

                .square {
                    width: 2.25vw;
                    height: 2.25vw;
                    max-width: 25px;
                    max-height: 25px;
                    background: linear-gradient(-93.94deg, #F4D941 0%, #A8803E 100%);
                    transform: rotate(-45deg);
                }
            }

            &.left-card {
                .connect-line {
                    top: 40px;
                    left: calc(100% + 1.5vw);
                    
                    @media (min-width: 901px) {
                        left: calc(100% + .7vw);
                    }

                    @media (min-width: 1100px) {
                        left: calc(100% + 1vw);
                    }
                    
                    @media (min-width: 1150px) {
                        left: calc(100% + 1.3vw);
                    }

                    @media (min-width: 1200px) {
                        left: calc(100% + 1.5vw);
                    }

                    @media (min-width: 1250px) {
                        left: calc(100% + 1.6vw);
                    }
                    
                    @media (min-width: 1300px) {
                        left: calc(100% + 1.7vw);
                    }

                    @media (min-width: 1320px) {
                        left: calc(100% + 1.9vw);
                    }

                    @media (min-width: 1350px) {
                        left: calc(100% + 1.9vw);
                    }

                    @media (min-width: 1400px) {
                        left: calc(100% + 2vw);
                    }

                    @media (min-width: 1500px) {
                        left: calc(100% + 1.75vw);
                    }

                    @media (min-width: 1550px) {
                        left: calc(100% + 1.70vw);
                    }

                    @media (min-width: 1600px) {
                        left: calc(100% + 1.5vw);
                    }

                    @media (min-width: 1700px) {
                        left: calc(100% + 1.4vw);
                    }

                    @media (min-width: 1900px) {
                        left: calc(100% + 1.3vw);
                    }
                }

                @media (max-width: ${screenSize.mobile}) {
                    margin-bottom: ${space.md};
                }
            }

            &.right-card {
                .connect-line {
                    flex-direction: row-reverse;
                    bottom: 40px;
                    right: calc(100% + 1.5vw);

                    @media (min-width: 901px) {
                        right: calc(100% + .7vw);
                    }

                    @media (min-width: 1100px) {
                        right: calc(100% + 1vw);
                    }
                    
                    @media (min-width: 1150px) {
                        right: calc(100% + 1.3vw);
                    }

                    @media (min-width: 1200px) {
                        right: calc(100% + 1.5vw);
                    }

                    @media (min-width: 1250px) {
                        right: calc(100% + 1.6vw);
                    }
                    
                    @media (min-width: 1300px) {
                        right: calc(100% + 1.7vw);
                    }

                    @media (min-width: 1320px) {
                        right: calc(100% + 1.9vw);
                    }

                    @media (min-width: 1350px) {
                        right: calc(100% + 1.9vw);
                    }

                    @media (min-width: 1400px) {
                        right: calc(100% + 2vw);
                    }

                    @media (min-width: 1500px) {
                        right: calc(100% + 1.75vw);
                    }

                    @media (min-width: 1550px) {
                        right: calc(100% + 1.70vw);
                    }

                    @media (min-width: 1600px) {
                        right: calc(100% + 1.5vw);
                    }

                    @media (min-width: 1700px) {
                        right: calc(100% + 1.4vw);
                    }

                    @media (min-width: 1900px) {
                        right: calc(100% + 1.3vw);
                    }
                }
            }

            &.top {
                .connect-line {
                    left: 50%;
                    transform: translate(-50%);
                    bottom: calc(100% + 30px);

                    .inner-line {
                        display: none;
                    }
                }
            }

        }
        .step-container {
            @media (max-width: ${screenSize.mobile}) {
                margin-bottom: ${space.md} !important;
            }
        }

    `}
`