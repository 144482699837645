import Card from 'blocks/Card'
import { Row, Container, RowItem, Col } from 'blocks/CommonStyles'
import { Text, Title } from 'blocks/Typography'
import DescriptionBox from 'components/DescriptionBox'
import StyledFeatures from './styles'
import Carousel from 'react-elastic-carousel'

const breakPoint = [
    { width: 400, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 4 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
    { width: 1750, itemsToShow: 4 },
  ]

const Features: React.FC = () => (
    <StyledFeatures>
        <img src='/static/images/bg.png' alt='bg' className='feature-bg' />
        <DescriptionBox
            title='Features'
            secondaryTitle='Metaplug’s Features'
            id='features'
        >
            <Container space='small'>Lorem ipsum dolor sit amet, consectetur <br /> adipiscing elit, sed do eiusmod tempor </Container>
            <Carousel isRTL={false} itemsToShow={4} enableSwipe={true} disableArrowsOnEnd={true} breakPoints={breakPoint}>
                <Card space='x-small'>
                    <Col>
                        <Container space='xx-small'>
                            <Title size='small' color='gold'>Back to Community!</Title>
                        </Container>
                        <Container space='xx-small'>
                            <Text>Are you Ready? Just by Owning a Metaplug you will be supporting incarcerated people by helping them learn about...</Text>
                        </Container>
                        <Container space='xx-small' className='image-container'>
                            <img src='/static/images/feature-item-blue.svg' alt='feature' />
                        </Container>
                    </Col>
                </Card>
                <Card space='x-small'>
                    <Col>
                        <Container space='xx-small'>
                            <Title size='small' color='gold'>Giveaways!</Title>
                        </Container>
                        <Container space='xx-small'>
                            <Text>Also owning a metaplug will enroll you into special giveaways and twice a year a certain amount of  people will randomly be...</Text>
                        </Container>
                        <Container space='xx-small' className='image-container'>
                            <img src='/static/images/feature-item-green.svg' alt='feature' />
                        </Container>
                    </Col>
                </Card>
                <Card space='x-small'>
                    <Col>
                        <Container space='xx-small'>
                            <Title size='small' color='gold'>Cartel Boss Status</Title>
                        </Container>
                        <Container space='xx-small'>
                            <Text> Along with owning a MetaPlug, owners of the Cartel Boss rank (15/10,000) will be automatically guaran...</Text>
                        </Container>
                        <Container space='xx-small' className='image-container'>
                            <img src='/static/images/feature-item-yellow.svg' alt='feature' />
                        </Container>
                    </Col>
                </Card>
                <Card space='x-small'>
                    <Col>
                        <Container space='xx-small'>
                            <Title size='small' color='gold'>Commercial Rights</Title>
                        </Container>
                        <Container space='xx-small'>
                            <Text>Owning a MetaPlug will also give you the ability to use it for commercial rights for as long as you own it.</Text>
                        </Container>
                        <Container space='xx-small' className='image-container'>
                            <img src='/static/images/feature-item-pink.svg' alt='feature' />
                        </Container>
                    </Col>
                </Card>
            </Carousel>
            {/* <Row align='stretch'>
                <RowItem>
                    <Card space='x-small'>
                        <Col>
                            <Container space='xx-small'>
                                <Title size='small' color='gold'>Back to Community!</Title>
                            </Container>
                            <Container space='xx-small'>
                                <Text>Are you Ready? Just by Owning a Metaplug you will be supporting incarcerated people by helping them learn about...</Text>
                            </Container>
                            <Container space='xx-small' className='image-container'>
                                <img src='/static/images/feature-item-blue.png' alt='feature' />
                            </Container>
                        </Col>
                    </Card>
                </RowItem>
                <RowItem>
                    <Card space='x-small'>
                        <Col>
                            <Container space='xx-small'>
                                <Title size='small' color='gold'>Giveaways!</Title>
                            </Container>
                            <Container space='xx-small'>
                                <Text>Also owning a metaplug will enroll you into special giveaways and twice a year a certain amount of  people will randomly be...</Text>
                            </Container>
                            <Container space='xx-small' className='image-container'>
                                <img src='/static/images/feature-item-green.png' alt='feature' />
                            </Container>
                        </Col>
                    </Card>
                </RowItem>
                <RowItem>
                    <Card space='x-small'>
                        <Col>
                            <Container space='xx-small'>
                                <Title size='small' color='gold'>Cartel Boss Status</Title>
                            </Container>
                            <Container space='xx-small'>
                                <Text> Along with owning a MetaPlug, owners of the Cartel Boss rank (15/10,000) will be automatically guaran...</Text>
                            </Container>
                            <Container space='xx-small' className='image-container'>
                                <img src='/static/images/feature-item-yellow.png' alt='feature' />
                            </Container>
                        </Col>
                    </Card>
                </RowItem>
                <RowItem>
                    <Card space='x-small'>
                        <Col>
                            <Container space='xx-small'>
                                <Title size='small' color='gold'>Commercial Rights</Title>
                            </Container>
                            <Container space='xx-small'>
                                <Text>Owning a MetaPlug will also give you the ability to use it for commercial rights for as long as you own it.</Text>
                            </Container>
                            <Container space='xx-small' className='image-container'>
                                <img src='/static/images/feature-item-pink.png' alt='feature' />
                            </Container>
                        </Col>
                    </Card>
                </RowItem>
            </Row> */}
        </DescriptionBox>
    </StyledFeatures>
)

export default Features