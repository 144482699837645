import styled, { css } from 'styled-components'

interface Props {
    backgroundFade?: 'right' | 'left';
    cardSpace?:'x-small' | 'small' | 'medium' | 'large';
}

export default styled.div<Props>`
    ${({ theme: { color, space, radius, mode }, backgroundFade, cardSpace }) => {
        const getCardSpace = () => {
            switch(cardSpace) {
                case 'x-small': return space.xs
                case 'small': return space.sm
                case 'medium': return space.md
                case 'large': return space.xl

                default: return ''
            }
        }

        return css`
            border: 2px solid ${mode === 'dark' ? color.gray[60] : color.white};
            border-radius: ${radius.md};
            padding: ${getCardSpace()};
            background: ${mode === 'dark' ? 
                `linear-gradient(to ${backgroundFade}, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 100%)` : 
                `linear-gradient(95.68deg, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%)`
            };
        `
    }}
`