import styled, { css } from 'styled-components'

// Col
interface ColProps {
    align?: 'center' | 'stretch' | 'flex-start' | 'flex-end';
    justify?: 'center' | 'space-between' | 'flex-start' | 'flex-end' | 'stretch';
}

const Col = styled.div<ColProps>`
  ${({ justify, align }) => css`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: ${justify};
    align-items: ${align};
  `}
`

Col.defaultProps = {
    align: 'stretch',
    justify: 'flex-start',
}

// Row
interface RowProps {
    align?: 'center' | 'stretch' | 'flex-start' | 'flex-end';
    justify?: 'center' | 'space-between' | 'flex-start' | 'flex-end' | 'space-around';
    flex?: string;
    width?: number | null;
    className?: string;
    wrap?: boolean;
}

const Row = styled.div<RowProps>`
  ${({ justify, align, flex, width, wrap }) => css`
    display: flex;
    ${wrap ? 'flex-wrap: wrap;' : ''}
    justify-content: ${justify};
    align-items: ${align};
    flex: ${flex};
    ${width ? `width: ${width}px` : 'width: 100%'};
  `}
`

Row.defaultProps = {
    align: 'stretch',
    justify: 'flex-start',
    flex: 'unset',
    width: null,
    wrap: true,
}

// Row Item
interface RowItemProps {
    flex?: string | false;
    width?: number | null;
    className?: string;
    gap?: 'small' | 'medium' | 'large' | false;
}

const RowItem = styled.div<RowItemProps>`
    ${({ theme: { space }, flex, width, gap }) => css`
        ${flex ? `flex: ${flex};` : ''}
        ${width ? `min-width: ${width}px` : ''};
        ${width ? `max-width: ${width}px` : ''};

        &:not(&:last-child) {
            ${gap ? `margin-right: ${
                gap === 'small' ?
                    space.md : gap === 'medium' ?
                    space.lg : gap === 'large' ? '6em' : ''};` : gap
            }
        }
    `}
`

RowItem.defaultProps = {
    flex: '1',
    width: null,
    gap: 'small',
}

// Container 
interface ContainerProps {
    space?: 'xx-small' | 'x-small' | 'small' | 'normal' | 'medium' | 'large' | 'x-large';
}

const Container = styled.div<ContainerProps>`
    ${({ theme, space }) => {
        const getSpace = () => {
            switch(space) {
                case 'xx-small': return theme.space.xxxs
                case 'x-small': return theme.space.xs
                case 'small': return theme.space.sm
                case 'normal': return theme.space.md
                case 'medium': return theme.space.lg
                case 'large': return theme.space.xl
                case 'x-large': return '6em'
                
                default: return ''
            }
        }

        return css`
            &:not(&:last-child) {
                margin-bottom: ${getSpace()};
            }
        `}
    }
`

Container.defaultProps = {
    space: 'normal',
}

// Divider
interface DividerProps {
    space?: 'small' | 'normal' | 'medium' | 'large';
}

const Divider = styled.div<DividerProps>`
    ${({ theme, space }) => {
        const getSpace = () => {
            switch(space) {
                case 'small': return theme.space.sm
                case 'normal': return theme.space.md
                case 'medium': return theme.space.lg
                case 'large': return theme.space.xl
                
                default: return ''
            }
        }

        return css`
            border-bottom: 1px solid ${theme.color.gray[80]};
            display: block;
            margin: ${getSpace} 0;
        `
    }}
`

export {
    Col,
    Row,
    RowItem,
    Container,
    Divider,
}