 import Button from 'blocks/Button'
import Card from 'blocks/Card'
import { Col, Container, Divider, Row } from 'blocks/CommonStyles'
import { Title, Text } from 'blocks/Typography'
import ImageList from 'components/ImageList'
import { ThemeMode } from 'context/ThemeModeProvider'
import { useContext } from 'react'
import StyledMint from './styles'

const Mint: React.FC = () => {
    const { mode } = useContext(ThemeMode)

    return (
        <StyledMint>
            <ImageList />

            <Divider space='medium'/>

            <Row justify='center' align='center' className='card-wrapper'>
                <img src="/static/images/bg.png" alt="bg" className='mint-bg' />
                <img src={`/static/images/mint/top-left-${mode}.svg`}alt="bg" className='top-left-bg' />
                <img src={`/static/images/mint/bottom-left-${mode}.svg`} alt="bg" className='bottom-left-bg' />
                <img src={`/static/images/mint/bottom-right-${mode}.svg`} alt="bg" className='bottom-right-bg' />

                 
                <Card className='card' space='large'>
                    <Col align='center'>
                        <Container space='x-small'>
                            <Title size='large'>0 / 10000</Title>
                        </Container>
                        <Container space='x-small'>
                            <Text>Ox7F9151A64BFAO...</Text>
                        </Container>
                        <Container space='x-small'>
                            <Title color='gold'>1 B$B costs 0.1 ETH,</Title>
                        </Container>
                        <Container space='x-small'>
                            <Text>Excluding gas fees.</Text>
                        </Container>
                        <Container space='x-small'>
                            <Text>Connect to the Ethereum network.</Text>
                        </Container>

                        <Container>
                            <Button color={mode === 'dark' ? 'white' : 'dark'}>Connect</Button>
                        </Container>

                        <div className='description'>Install Metamask.</div>
                    </Col>
                </Card>
            </Row>
        </StyledMint>
    )
}

export default Mint