import styled, { css } from 'styled-components'

export default styled.div`
    ${({ theme: { space, radius } }) => css`
        .carousel-wrapper {
            margin-top: ${space.md};
            position: relative;

            .rarity-bg {
                position: absolute;
                z-index: -1;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .card {
            width: 100%;
            margin-inline: ${space.xs};
            border-radius: ${radius.xl};

            .image-container {
                img {
                    width: 100%;
                }
            }

            .text {
                font-size: 17px;
            }
        }
    `}
`