import styled, { css } from 'styled-components'

export default styled.div`
    ${({ theme: { space, screenSize } }) => css`
    
        .image-list {
            padding: ${space.xxl} ${space.md} ${space.md};
        }

        .image-item {
            margin-right: 5px;

            @media (max-width: ${screenSize.mobile}) {
                text-align: center;
            }
        }
    `}

`   