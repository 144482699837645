import styled, { css } from 'styled-components'

export default styled.div`
    ${({ theme: { color, space, screenSize } }) => css`
        .secondary-head {
            padding: ${space.sm};

            @media (max-width: ${screenSize.mobile}) {
                padding: 0;
            }

            &__wrapper {
                height: 540px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;

                .card-wrapper {
                    height: 100%;

                    .card {
                        border-radius: 40px;
                        backdrop-filter: blur(15px);
                        min-width: 90%;
                        background: linear-gradient(95.68deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.5) 100%);

                        .text {
                            font-weight: 500;
                            color: ${color.black};
                        }
                    }
                }
            }
        }

        .about-box {
            position: relative;
            padding-top: ${space.xxl};

            .about-bg {
                position: absolute;
                z-index: -1;
                top: -350px;
                right: -30vw;
            }
        }

        .nft-box {
            position: relative;

            .nft-bg {
                position: absolute;
                top: -400px;
                right: -30vw;
                z-index: -1;
            }
        }

    `}
`