import React, { useContext } from 'react'
import { ThemeProvider } from 'styled-components'
import getTheme from 'theme'
import GlobalStyle from 'globalStyles'
import { ThemeMode } from 'context/ThemeModeProvider'
import Routes from 'Routes'

const AppWithTheme = () => {
  const { mode } = useContext(ThemeMode)
  
  const themeObject = getTheme(mode)

  return (
    <ThemeProvider theme={{
      ...themeObject,
    }}>
      <GlobalStyle />
      <div className="App">
        <Routes />
      </div>
    </ThemeProvider>
  )
}

const App: React.FC = () => (
		<AppWithTheme />
)

export default App
