import { Container, Row, RowItem, Col } from "blocks/CommonStyles"
import { Title } from "blocks/Typography"
import Discords from "icons/Discords"
import Instagram from "icons/Instagram"
import Telegram from "icons/Telegrams"
import Twitter from "icons/Twitter"
import StyledSocialMedia from './styles'

const SocialMedia: React.FC = () => (
    <StyledSocialMedia>
        <Container space="normal">
            <Title size="medium" align="center">Join our Family & Get Plugged in! : </Title>
        </Container>

        <Row justify="center">
            <RowItem width={90} gap='large' className="item">
                <Row align="center" justify="center" className="button-icon">
                    <Discords />
                </Row>
            </RowItem>
            <RowItem width={90} gap='large' className="item">
                <Row align="center" justify="center" className="button-icon">
                    <Twitter />
                </Row>
            </RowItem>
            <RowItem width={90} gap='large' className="item">
                <Row align="center" justify="center" className="button-icon">
                    <Telegram />
                </Row>
            </RowItem>
            <RowItem width={90} gap='large' className="item">
                <Row align="center" justify="center" className="button-icon">
                    <Instagram />
                </Row>
            </RowItem>
        </Row>
    </StyledSocialMedia>
)

interface SmallSocialMediaProps {
    direction?: 'row' | 'col';
}

const SmallSocialMedia: React.FC<SmallSocialMediaProps> = ({direction}) => (
    direction === 'row' ?
        <Row align="center"></Row> :
        <Col></Col>
)   

SmallSocialMedia.defaultProps = {
    direction: 'row',
}

export default SocialMedia
export {
    SmallSocialMedia,
}