const globalTheme = (mode: string = 'dark') => ({
    color: {
        dark: '#010001',
        light: '#F8F9FA',
        gray: {
            20: '#FDFDFD',
            30: '#EBEBEB',
            40: '#cbcacb',
            60: '#a7a7a7',
            80: '#3E3E3E',
        },
        white: '#fff',
        black: '#151515',
    },
    fs: {
        xxs: '14px',
        xs: '16px',
        sm: '18px',
        md: '20px',
        lg: '24px',
        xl: '28px',
        xxl: '30px',
        xxxl: '32px',
    },
    space: {
        xxxs: '.25em',
        xxs: '.5em',
        xs: '1em',
        sm: '1.5em',
        md: '2em',
        lg: '2.5em',
        xl: '3em',
        xxl: '4em',
        xxxl: '5em',
    },
    radius: {
        xs: '5px',
        sm: '8px',
        md: '10px',
        lg: '15px',
        xl: '20px',
        circle: '50%',
    },
    boxShadow: {
        
    },
    mode,
    screenSize: {
        mobile: '900px',
    }
})

const getTheme = (mode: string = 'dark') => globalTheme(mode)

export default getTheme