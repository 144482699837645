import styled, { css } from 'styled-components'

export default styled.div`
    ${({ theme: { space, screenSize } }) => css`
        .card-wrapper {
            padding: 15em 0 25em;
            position: relative;

            @media (max-width: ${screenSize.mobile}) {
                padding: 2em 1em;
            }

            .mint-bg {
                position: absolute;
                top 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: -1;
            }

            .top-left-bg, .bottom-right-bg, .bottom-left-bg {
                position: absolute;
            }

            .top-left-bg {
                top: 100px;
                left: 1vw;
            }

            .bottom-right-bg {
                right: 0vw;
                bottom: 9em;
            }

            .bottom-left-bg {
                bottom: 0;
                left: 10vw;
            }

            .card {
                width: 612px;
                max-width: 100%;
                border-radius: 30px;
            }

            @media (max-width: ${screenSize.mobile}) {
                .top-left-bg, .bottom-right-bg, .bottom-left-bg {
                    display: none;
                }
            }
        }
    `}
`
