import { ReactNode, useState } from "react"
import { Text } from "blocks/Typography"
import StyledQuestion from './styles'
import { Row } from "blocks/CommonStyles"

interface Props {
    question: string;
    children: ReactNode;
}

const Question: React.FC<Props> = ({ question, children }) => {
    const [show, setShow] = useState(false)

    return (
        <StyledQuestion show={show}>
            <div className="question" onClick={() => setShow(!show)}>
                <Text className="text-color bold question-text" color={show ? 'dark' : 'light'}>{question}</Text>
                <Row className="icon"></Row>
            </div>
            <div className="answer">
                <Text className="text-color" color="light">{children}</Text>
            </div>
        </StyledQuestion>
    )
}

export default Question