import styled, { css } from 'styled-components'

export default styled.div`
    ${({ theme: { fs, space, color, mode, screenSize } }) => css`
        padding: ${space.md};

        .title {
            font-weight: 500;
        }

        .text {
            font-size: ${fs.sm};
            color: ${mode === 'dark' ? color.gray[30] : color.black};
        }
        
        .image-desc {
            margin-left: ${space.md};
            
            @media (max-width: ${screenSize.mobile}) {
                margin-left: 0;
                margin-top: ${space.md};

            }

            img {
                width: 100%;
            }
        }

        .item, .image-desc {
            @media (max-width: ${screenSize.mobile}) {
                width: 100%;
                flex: 0 0 100%;
            }
        }
        
    `}
`