import styled, { css } from 'styled-components'

// Button
interface ButtonProps {
    buttonColor?: 'light' | 'dark' | 'outlined' | 'fade' | 'white';
    width?: number | null;
    backgroundFade?: 'left' | 'right';
}

export default styled.div<ButtonProps>`
    ${({ theme: { fs, space, radius, color, screenSize }, buttonColor, backgroundFade, width }) => {

        const buttonTheme = () => {
            switch(buttonColor) {
                case 'dark': 
                    return `
                        background: ${color.black};
                        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
                        color: #fff;
                        border-color: ${color.black}
                    `
    
                case 'light':
                    return `
                        border-color: ${color.gray[20]};
                        background: ${color.gray[20]};
                        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
                        color: ${color.dark};
                    `
    
                case 'outlined': 
                    return `
                        border-color: ${color.dark};
                        background: transparent;
                        color: ${color.dark};
                    `

                case 'fade':
                    return `
                        border-color: ${color.gray[40]};
                        background: linear-gradient(to ${backgroundFade}, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 100%);
                        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4);
                    `

                case 'white':
                    return `
                        background: ${color.white};
                        border-color: ${color.white};
                        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
                        color: ${color.black};
                    `
    
                default: return ''
            }
        }

        return css`
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: ${fs.sm};
            font-weight: bold;
            padding: ${space.xs};
            border-radius: ${radius.md};
            border: 1px solid;
            cursor: pointer;
            height: 58px;
            user-select: none;
            min-width: 193px;
            ${width ? `width: ${width}px;` : 'width: max-content;'}

            ${buttonTheme()}
        `}
    }
`

// Button Wrapper
export const StyleButtonWrapper = styled.div`
    ${({ theme: { space, screenSize } }) =>  css`
            .button {
                &:not(&:last-child) {
                    margin-right: ${space.xs};

                    @media (max-width: 500px) {
                        margin-right: 0;
                        margin-bottom: ${space.xs};
                    }
                }
            }
        `
    }
`