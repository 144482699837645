import { createGlobalStyle, css } from 'styled-components'

import Regular from 'fonts/Orbitron-Regular.ttf'
import SemiBold from 'fonts/Orbitron-SemiBold.ttf'
import Medium from 'fonts/Orbitron-Medium.ttf'
import Bold from 'fonts/Orbitron-Bold.ttf'
import ExtraBold from 'fonts/Orbitron-ExtraBold.ttf'
import Black from './fonts/Orbitron-Black.ttf'

const GlobalStyle = createGlobalStyle`
  ${({ theme: { color, mode, screenSize } }) => css`


      @font-face {
        font-family: Orbitron;
        font-style: normal;
        font-weight: 400;
        src: url(${Regular});
      }

      @font-face {
        font-family: Orbitron;
        font-style: normal;
        font-weight: normal;
        src: url(${Regular});
      }

      @font-face {
        font-family: Orbitron;
        font-style: normal;
        font-weight: 500;
        src: url(${Medium});
      }

      @font-face {
        font-family: Orbitron;
        font-style: normal;
        font-weight: 600;
        src: url(${SemiBold});
      }

      @font-face {
        font-family: Orbitron;
        font-style: normal;
        font-weight: 700;
        src: url(${Bold});
      }

      @font-face {
        font-family: Orbitron;
        font-style: normal;
        font-weight: 800;
        src: url(${ExtraBold});
      }

      @font-face {
        font-family: Orbitron;
        font-style: normal;
        font-weight: 900;
        src: url(${Black});
      }

      * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      }

      html {
        font-familay: Orbitron;
        background: ${mode === 'dark' ? color.dark : color.light};
        font-size: 16px;
        color: ${mode === 'dark' ? color.light : color.dark};
        letter-spacing: 0.01em;
        overflow-x: hidden;
        scroll-behavior: smooth;
      }

      body {
        font-family: Orbitron;

        @media (max-width: ${screenSize.mobile}) {
          overflow-x: hidden;
        }
      }

      a {
        color: ${mode === 'dark' ? color.gray[30] : color.black};
        text-decoration: none;
      }

      #App {
        overflow-x: hidden;
      }

      .rec-item-wrapper {
        height: 100%;
      }
  `}
`
 
export default GlobalStyle;
