const Discords: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="36" viewBox="0 0 30 36" fill="none">
        <path d="M11.7933 15.3333C12.7933 15.3333 13.6033 16.0833 13.585 17C13.585 17.9166 12.795 18.6666 11.7933 18.6666C10.81 18.6666 10 17.9166 10 17C10 16.0833 10.7917 15.3333 11.7933 15.3333ZM18.2067 15.3333C19.2083 15.3333 20 16.0833 20 17C20 17.9166 19.2083 18.6666 18.2067 18.6666C17.2233 18.6666 16.415 17.9166 16.415 17C16.415 16.0833 17.205 15.3333 18.2067 15.3333ZM26.485 0.333313C28.4233 0.333313 30 1.94331 30 3.93831V35.3333L26.315 32.0083L24.24 30.0483L22.045 27.965L22.955 31.2033H3.515C1.57667 31.2033 0 29.5933 0 27.5983V3.93831C0 1.94331 1.57667 0.333313 3.515 0.333313H26.4833H26.485ZM19.8683 23.1883C23.6567 23.0666 25.115 20.5283 25.115 20.5283C25.115 14.8933 22.645 10.325 22.645 10.325C20.1783 8.43665 17.8283 8.48831 17.8283 8.48831L17.5883 8.76831C20.5033 9.67831 21.8567 10.9916 21.8567 10.9916C20.2651 10.095 18.5111 9.52387 16.6967 9.31165C15.5457 9.18165 14.3832 9.19285 13.235 9.34498C13.1317 9.34498 13.045 9.36331 12.9433 9.37998C12.3433 9.43331 10.885 9.65998 9.05167 10.4833C8.41833 10.78 8.04 10.9916 8.04 10.9916C8.04 10.9916 9.46333 9.60831 12.5483 8.69831L12.3767 8.48831C12.3767 8.48831 10.0283 8.43665 7.56 10.3266C7.56 10.3266 5.09167 14.8933 5.09167 20.5283C5.09167 20.5283 6.53167 23.065 10.32 23.1883C10.32 23.1883 10.9533 22.4016 11.47 21.7366C9.29167 21.07 8.47 19.67 8.47 19.67C8.47 19.67 8.64 19.7933 8.94833 19.9683C8.965 19.985 8.98167 20.0033 9.01667 20.02C9.06833 20.0566 9.12 20.0733 9.17167 20.1083C9.6 20.3533 10.0283 20.545 10.4217 20.7033C11.125 20.9833 11.965 21.2633 12.9433 21.4566C14.4089 21.7439 15.9157 21.7496 17.3833 21.4733C18.2382 21.3207 19.0722 21.0685 19.8683 20.7216C20.4683 20.4933 21.1367 20.16 21.84 19.6883C21.84 19.6883 20.9833 21.1233 18.7367 21.7716C19.2517 22.4366 19.87 23.1883 19.87 23.1883H19.8683Z" fill="#FDFDFD"/>
    </svg>
)

export const SmallDiscords: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="21" viewBox="0 0 18 21" fill="none">
        <path d="M7.076 9C7.676 9 8.162 9.45 8.151 10C8.151 10.55 7.677 11 7.076 11C6.486 11 6 10.55 6 10C6 9.45 6.475 9 7.076 9ZM10.924 9C11.525 9 12 9.45 12 10C12 10.55 11.525 11 10.924 11C10.334 11 9.849 10.55 9.849 10C9.849 9.45 10.323 9 10.924 9ZM15.891 0C17.054 0 18 0.966 18 2.163V21L15.789 19.005L14.544 17.829L13.227 16.579L13.773 18.522H2.109C0.946 18.522 0 17.556 0 16.359V2.163C0 0.966 0.946 0 2.109 0H15.89H15.891ZM11.921 13.713C14.194 13.64 15.069 12.117 15.069 12.117C15.069 8.736 13.587 5.995 13.587 5.995C12.107 4.862 10.697 4.893 10.697 4.893L10.553 5.061C12.302 5.607 13.114 6.395 13.114 6.395C12.1591 5.85699 11.1066 5.51434 10.018 5.387C9.32744 5.309 8.62993 5.31572 7.941 5.407C7.879 5.407 7.827 5.418 7.766 5.428C7.406 5.46 6.531 5.596 5.431 6.09C5.051 6.268 4.824 6.395 4.824 6.395C4.824 6.395 5.678 5.565 7.529 5.019L7.426 4.893C7.426 4.893 6.017 4.862 4.536 5.996C4.536 5.996 3.055 8.736 3.055 12.117C3.055 12.117 3.919 13.639 6.192 13.713C6.192 13.713 6.572 13.241 6.882 12.842C5.575 12.442 5.082 11.602 5.082 11.602C5.082 11.602 5.184 11.676 5.369 11.781C5.379 11.791 5.389 11.802 5.41 11.812C5.441 11.834 5.472 11.844 5.503 11.865C5.76 12.012 6.017 12.127 6.253 12.222C6.675 12.39 7.179 12.558 7.766 12.674C8.64532 12.8464 9.54941 12.8498 10.43 12.684C10.9429 12.5925 11.4433 12.4411 11.921 12.233C12.281 12.096 12.682 11.896 13.104 11.613C13.104 11.613 12.59 12.474 11.242 12.863C11.551 13.262 11.922 13.713 11.922 13.713H11.921Z" fill="#FDFDFD"/>
    </svg>
)

export default Discords