const Telegram: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
        <path d="M17 33.6666C7.79498 33.6666 0.333313 26.205 0.333313 17C0.333313 7.79498 7.79498 0.333313 17 0.333313C26.205 0.333313 33.6666 7.79498 33.6666 17C33.6666 26.205 26.205 33.6666 17 33.6666ZM11.8166 18.95L11.8383 18.9383L13.2883 23.7216C13.475 24.24 13.7316 24.3333 14.0433 24.29C14.3566 24.2483 14.5216 24.08 14.7266 23.8833L16.7066 21.97L20.9566 25.1166C21.7333 25.545 22.2916 25.3233 22.485 24.3966L25.2466 11.36C25.5516 10.1466 25.0183 9.65998 24.0766 10.0466L7.85498 16.3133C6.74831 16.7566 6.75498 17.3766 7.65498 17.6516L11.8166 18.9516V18.95Z" fill="#FDFDFD"/>
    </svg>
)

export const SmallTelegram: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM6.89 11.17L6.903 11.163L7.773 14.033C7.885 14.344 8.039 14.4 8.226 14.374C8.414 14.349 8.513 14.248 8.636 14.13L9.824 12.982L12.374 14.87C12.84 15.127 13.175 14.994 13.291 14.438L14.948 6.616C15.131 5.888 14.811 5.596 14.246 5.828L4.513 9.588C3.849 9.854 3.853 10.226 4.393 10.391L6.89 11.171V11.17Z" fill="#FDFDFD"/>
    </svg>
)

export default Telegram