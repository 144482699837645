import styled, { css } from 'styled-components'

export default styled.div`
    ${({ theme: { space, color, mode, screenSize } }) => css`
        display: flex;
        justify-content: center;
        min-height: 100vh;
        overflow-x: hidden;

        .left, .right {
            width: 96px;

            @media (max-width: ${screenSize.mobile}) {
                display: none;
            }
        }

        .main {
            border: 1px solid ${color.gray[80]};
            border-top: none;
            border-bottom: none;
            width: 1248px;
            max-width: 100%;
        }

        .rotate {
            position: fixed;
            top: 50%;
            left: calc((92px / 2) - 3px);
            transform: translateY(-50%);
        }

        .icons-sidebar {
            position: fixed;
            top: 10em;
            right: calc((92px / 2) - 5px);

            svg {
                margin-bottom: ${space.md};
            }
        }

        .left, .right {
            svg {
                & > * {
                    fill: ${mode === 'dark' ? color.light : color.black};
                }
            }
        }
    `}
`