import styled, { css } from 'styled-components'

export default styled.header`
    ${({ theme: { color, space, radius, mode, screenSize } }) => css`
        border-bottom: 1px solid ${color.gray[80]};
        height: 100px;
        display: flex;
        align-items: center;
        padding: ${space.sm};

        .logo {
            width: 120px;
            // height: 70px;
            display: flex;

            @media (max-width: ${screenSize.mobile}) {
                width: 90px;

                img {
                    width: 100%;
                }
            }
        }

        .navbar {
            &__item {
                margin: 0 ${space.xs};
            }
            
            @media (max-width: 1270px) {
                display: none;
            }
        }

        .mode {
            width: 58px;
            height: 58px;
            border-radius: ${radius.circle};
            cursor: pointer;
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;
            background: ${
                mode === 'dark' ?
                    'linear-gradient(95.68deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 100%)' : 
                    'linear-gradient(95.68deg, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%)'
            };

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transition: .2s cubic-bezier(0.21, 0.32, 0.32, 1.57);

                &.sun, &.moon {
                    transform: translate(-50%, 100%);
                    opacity: 0;
                    
                    &.active {
                        transform: translate(-50%, -50%);
                        opacity: 1;
                    }
                }
            }
            margin-right: ${space.md};

        }

        @media (max-width: 1270px) {
            .button-wrapper {
                display: none;
            }
        }
        
        .menu {
            display: none;

            @media (max-width: 1270px) {
                display: block;
            }
        }

        .menu-icon {
            display: flex;
            flex-direction: column;
            cursor: pointer;

            span {
                display: block;
                width: 30px;
                height: 2px;
                background: ${mode === 'dark' ? color.light : color.dark};
                border-radius: 20px;

                &:not(&:last-child) {
                    margin-bottom: 7px;
                }

                &:nth-child(2) {
                    width: 18px;
                }
                &:nth-child(3) {
                    width: 13px;
                }
            }
        }

        .sidemenu-container {
            position: fixed;
            inset: 0 0 0 0;
            z-index: -1;
            transition: .2s;
            
            .bg {
                opacity: 0;
                background: rgba(0,0,0, .5);
                transition: .2s;
                position: absolute;
                inset: 0 0 0 0;
            }
            
            .sidemenu {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 270px;
                transform: translateX(-270px);
                transition: .2s;
                display: flex;
                flex-direction: column;
                background: ${mode === 'dark' ? color.dark : color.light};
                padding: ${space.sm};

                &__header {
                    margin-bottom: ${space.md};
                    display: flex;
                    justify-content: space-between;

                    .close-icon {
                        width: 15px;
                        heigt: 15px;
                        position: relative;
                        display: block;

                        span {
                            width: 2px;
                            height: 15px;
                            background: ${mode === 'light' ? color.dark : color.light};
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            
                            &:nth-child(1) {
                                transform: translate(-50%, -50%) rotate(-45deg);
                            }
                            
                            &:nth-child(2) {
                                transform: translate(-50%, -50%) rotate(45deg);
                            }
                        }
                    }

                    .mode {
                        width: 40px;
                        height: 40px;
                    }
                }

                &__menu {
                    display: flex;
                    flex-direction: column;

                    .navbar__item {
                        margin: 0 0 ${space.xs};
                    }
                }

                &__action {
                    display: flex;
                    justify-content: center;
                    margin-top: ${space.sm};

                    .button {
                        width: 100px;
                        font-size: 12px;
                        min-width: 170px;
                    }
                }
            }

            &.active {
                z-index: 10;

                .bg {
                    opacity: 1;
                }
    
                .sidemenu {
                    transform: translateX(0);
                }
            }
        }
    `}
`