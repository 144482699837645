import { Divider, Row, Container, RowItem } from 'blocks/CommonStyles'
import { Text } from 'blocks/Typography'
import DescriptionBox from 'components/DescriptionBox'
import ImageList from 'components/ImageList'
import Question from 'components/Question'
import { ThemeMode } from 'context/ThemeModeProvider'
import { useContext } from 'react'
import StyledFaq from './styles'

const Faq = () => {
    const { mode } = useContext(ThemeMode)

   return (
        <StyledFaq>
            <ImageList />

            <Divider space='medium' />

            <DescriptionBox 
                secondaryTitle='Frequency Ask Questions' 
                className='questions-container' 
                imgSrc={`/static/images/faq-image-${mode}.svg`}
            >
                <img src='/static/images/bg.png' alt='bg' className='faq-bg' />
                <Container space='small'>
                    <Text size='medium'>NFT collection  where anything is possible and all are welcome</Text>
                </Container>

                <Container space='x-small'>
                    <Question question='What’s an NFT?'>
                        A non-fungible token (NFT) is a unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable. NFTs can be used to represent items such as photos, videos, audio, and other types of
                    </Question>
                </Container>
                <Container space='x-small'>
                    <Question question='what is Metamask?'>
                        A non-fungible token (NFT) is a unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable. NFTs can be used to represent items such as photos, videos, audio, and other types of
                    </Question>
                </Container>
                <Container space='x-small'>
                    <Question question='How do I purchase a Metaplug NFT?'>
                        A non-fungible token (NFT) is a unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable. NFTs can be used to represent items such as photos, videos, audio, and other types of
                    </Question>
                </Container>
                <Container space='x-small'>
                    <Question question='Anything else to expect when purchasing ETH?'>
                        A non-fungible token (NFT) is a unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable. NFTs can be used to represent items such as photos, videos, audio, and other types of
                    </Question>
                </Container>
                <Container space='x-small'>
                    <Question question='what is Metamask?'>
                        A non-fungible token (NFT) is a unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable. NFTs can be used to represent items such as photos, videos, audio, and other types of
                    </Question>
                </Container>
                <Container space='x-small'>
                    <Question question='How do I purchase a Metaplug NFT?'>
                        A non-fungible token (NFT) is a unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable. NFTs can be used to represent items such as photos, videos, audio, and other types of
                    </Question>
                </Container>
                <Container space='x-small'>
                    <Question question='Anything else to expect when purchasing ETH?'>
                        A non-fungible token (NFT) is a unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable. NFTs can be used to represent items such as photos, videos, audio, and other types of
                    </Question>
                </Container>
                <Container space='x-small'>
                    <Question question='what is Metamask?'>
                        A non-fungible token (NFT) is a unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable. NFTs can be used to represent items such as photos, videos, audio, and other types of
                    </Question>
                </Container>
                <Container space='x-small'>
                    <Question question='How do I purchase a Metaplug NFT?'>
                        A non-fungible token (NFT) is a unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable. NFTs can be used to represent items such as photos, videos, audio, and other types of
                    </Question>
                </Container>
                <Container space='x-small'>
                    <Question question='Anything else to expect when purchasing ETH?'>
                        A non-fungible token (NFT) is a unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable. NFTs can be used to represent items such as photos, videos, audio, and other types of
                    </Question>
                </Container>
                <Container space='x-small'>
                    <Question question='what is Metamask?'>
                        A non-fungible token (NFT) is a unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable. NFTs can be used to represent items such as photos, videos, audio, and other types of
                    </Question>
                </Container>
            </DescriptionBox>
        </StyledFaq>
   )
}

export default Faq