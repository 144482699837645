import { Row, RowItem } from "blocks/CommonStyles"
import StyledImageList from './style'

const ImageList: React.FC = () => (
    <StyledImageList>
        <Row className='image-list' justify='center' align='center'>
            <RowItem className='image-item' gap={false}>
                <img src='/static/images/small/small-light-green.svg' alt='small' />
            </RowItem>
            <RowItem className='image-item' gap={false}>
                <img src='/static/images/small/small-pink.svg' alt='small' />
            </RowItem>
            <RowItem className='image-item' gap={false}>
                <img src='/static/images/small/small-blue.svg' alt='small' />
            </RowItem>
            <RowItem className='image-item' gap={false}>
                <img src='/static/images/small/small-yellow.svg' alt='small' />
            </RowItem>
            <RowItem className='image-item' gap={false}>
                <img src='/static/images/small/small-green.svg' alt='small' />
            </RowItem>
            <RowItem className='image-item' gap={false}>
                <img src='/static/images/small/small-light-green.svg' alt='small' />
            </RowItem>
            <RowItem className='image-item' gap={false}>
                <img src='/static/images/small/small-pink.svg' alt='small' />
            </RowItem>
            <RowItem className='image-item' gap={false}>
                <img src='/static/images/small/small-blue.svg' alt='small' />
            </RowItem>
            <RowItem className='image-item' gap={false}>
                <img src='/static/images/small/small-yellow.svg' alt='small' />
            </RowItem>
            <RowItem className='image-item' gap={false}>
                <img src='/static/images/small/small-green.svg' alt='small' />
            </RowItem>
        </Row>
    </StyledImageList>
)

export default ImageList