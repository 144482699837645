import styled, { css } from 'styled-components'

export default styled.div`
    ${({ theme: { space } }) => css`
        .card {
            margin-inline: ${space.xs};
            width: 100%;
        }

        .image-container {
            img {
                width: 100%;
            }
        }

        .carousel-wrapper {
            margin-top: ${space.md};
        }
    `}
`