import styled, { css } from 'styled-components'

export default styled.div`
    ${({ theme: { space, screenSize } }) => css`
        padding: ${space.md};
        position: relative;

        .partner-bg {
            position: absolute;
            z-index: -1;
            top: -500px;
            right: -40vw;
        }

        @media (max-width: ${screenSize.mobile}) {
            .item {
                flex: 0 0 calc(50% - ${space.sm});
                
                &:not(&:last-child) {
                    margin-right: 0;
                }
                
                &:last-child {
                    margin-right: 0;
                }
                
                &:not(&:nth-child(2n)) {
                    margin-right: ${space.sm} !important;
                }

                margin-bottom: ${space.sm};
            }

            .list {
                justify-content: center;
            }
        }


    `}
`