const Twitter: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="28" viewBox="0 0 35 28" fill="none">
        <path d="M34.9366 3.42654C33.6642 3.98937 32.3149 4.359 30.9333 4.52321C32.3895 3.65227 33.4795 2.28157 34 0.666544C32.6333 1.47988 31.135 2.04988 29.5733 2.35821C28.5243 1.23584 27.1339 0.491483 25.6182 0.240852C24.1025 -0.00977847 22.5465 0.247363 21.1921 0.972299C19.8376 1.69724 18.7606 2.84935 18.1285 4.24953C17.4964 5.64971 17.3445 7.21951 17.6966 8.71488C14.9251 8.57597 12.2139 7.85575 9.73878 6.60098C7.2637 5.34622 5.08018 3.58496 3.32997 1.43154C2.71045 2.49563 2.38489 3.70525 2.38663 4.93654C2.38663 7.35321 3.61663 9.48821 5.48663 10.7382C4.37998 10.7034 3.29769 10.4045 2.32997 9.86654V9.95321C2.3303 11.5627 2.88725 13.1226 3.90638 14.3683C4.92551 15.614 6.3441 16.469 7.92163 16.7882C6.89432 17.0666 5.81714 17.1076 4.77163 16.9082C5.21641 18.2936 6.08331 19.5052 7.25095 20.3734C8.41859 21.2416 9.82851 21.7229 11.2833 21.7499C9.83743 22.8854 8.18193 23.7248 6.41143 24.2202C4.64094 24.7155 2.79018 24.857 0.964966 24.6365C4.15113 26.6856 7.86013 27.7734 11.6483 27.7699C24.47 27.7699 31.4816 17.1482 31.4816 7.93654C31.4816 7.63654 31.4733 7.33321 31.46 7.03654C32.8247 6.05016 34.0026 4.82824 34.9383 3.42821L34.9366 3.42654Z" fill="#FDFDFD"/>
    </svg>
)

export const SmallTwitter: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none">
        <path d="M21.1618 2.65593C20.3984 2.99362 19.5888 3.2154 18.7598 3.31393C19.6336 2.79136 20.2875 1.96894 20.5998 0.999927C19.7798 1.48793 18.8808 1.82993 17.9438 2.01493C17.3144 1.34151 16.4802 0.89489 15.5708 0.744511C14.6614 0.594133 13.7277 0.748418 12.9151 1.18338C12.1024 1.61834 11.4562 2.30961 11.0769 3.14972C10.6976 3.98983 10.6066 4.93171 10.8178 5.82893C9.15492 5.74558 7.52814 5.31345 6.0431 4.56059C4.55805 3.80773 3.24794 2.75097 2.19781 1.45893C1.8261 2.09738 1.63076 2.82315 1.63181 3.56193C1.63181 5.01193 2.36981 6.29293 3.49181 7.04293C2.82782 7.02202 2.17844 6.84271 1.59781 6.51993V6.57193C1.59801 7.53763 1.93218 8.47354 2.54366 9.22099C3.15513 9.96843 4.00629 10.4814 4.95281 10.6729C4.33642 10.84 3.69012 10.8646 3.06281 10.7449C3.32968 11.5762 3.84982 12.3031 4.5504 12.824C5.25099 13.3449 6.09694 13.6337 6.96981 13.6499C6.10229 14.3313 5.10899 14.8349 4.04669 15.1321C2.98439 15.4293 1.87394 15.5142 0.778809 15.3819C2.69051 16.6114 4.9159 17.264 7.18881 17.2619C14.8818 17.2619 19.0888 10.8889 19.0888 5.36193C19.0888 5.18193 19.0838 4.99993 19.0758 4.82193C19.8947 4.23009 20.6014 3.49695 21.1628 2.65693L21.1618 2.65593Z" fill="#FDFDFD"/>
    </svg>
)

export default Twitter