import { Row } from 'blocks/CommonStyles'
import { ReactNode, FC } from 'react'
import StyledButton, { StyleButtonWrapper } from './styles'

// Button
interface ButtonProps {
    className?: string;
    color?: 'light' | 'dark' | 'outlined' | 'fade' | 'white';
    onClick?: () => void;
    width?: number | null;
    children: ReactNode;
    backgroundFade?: 'left' | 'right';
}

const Button: FC<ButtonProps> = ({
    className,
    onClick,
    children,
    color,
    width,
    backgroundFade,
}) => (
    <StyledButton 
        buttonColor={color}
        className={`button ${className}`}
        onClick={onClick}
        width={width}
        backgroundFade={backgroundFade}
    >
        {children}
    </StyledButton>
)

Button.defaultProps = {
    className: '',
    width: null,
    color: 'dark',
    backgroundFade: 'left',
}

// Button Wrapper
interface ButtonWrapperProps {
    className?: string;
    justify?: 'center' | 'flex-end' | 'flex-start';
    children: ReactNode;
}

const ButtonWrapper: FC<ButtonWrapperProps> = ({ justify, className, children }) => (
    <StyleButtonWrapper>
        <Row align='center' justify={justify} className={className}>
            {children}
        </Row>
    </StyleButtonWrapper>
)

ButtonWrapper.defaultProps = {
    className: '',
    justify: 'center',
}

export default Button
export { 
    ButtonWrapper,
}