import { useRef, useContext, useState } from 'react'
import { Row } from 'blocks/CommonStyles'
import Button, { ButtonWrapper } from 'blocks/Button'
import { ThemeMode } from 'context/ThemeModeProvider'
import StyledHeader from './styles'
import { Link } from 'react-router-dom'

const Header: React.FC = () => {
    const { mode, changeMode } = useContext(ThemeMode)
    const [showSidebar, setShowSidebar] = useState(false)

    const navLinks = useRef<{ name: string, url: string, link?: boolean }[]>([
        { name: 'About', url: '/#about', },
        { name: 'Rarity', url: '/#rarity', },
        { name: 'Roadmap', url: '/#roadmap', },
        { name: 'Features', url: '/#features', },
        { name: 'Team', url: '/#team', },
        { name: 'Merch', url: '/mint', link: true },
        { name: 'FAQ', url: '/faq', link: true },
    ])

    return (
        <StyledHeader>
            <Row align='center' justify='space-between'>
                <div className='logo'>
                    <Link to='/'>
                        <img src='/static/images/logo.svg' alt='logo' />
                    </Link>
                </div>

                <div className='navbar'>
                    {navLinks.current.map(item =>
                        item.link ?
                        <Link to={item.url} className='navbar__item'>{item.name}</Link> :
                        <a href={item.url} className='navbar__item'>{item.name}</a>    
                    )}
                </div>


                <ButtonWrapper className='button-wrapper'>
                    <div className='mode' onClick={() => changeMode()}>
                            <svg className={'moon' + (mode === 'light' ? ' active' : '')} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M19.5297 13.9299C19.3697 13.6599 18.9197 13.2399 17.7997 13.4399C17.1797 13.5499 16.5497 13.5999 15.9197 13.5699C13.5897 13.4699 11.4797 12.3999 10.0097 10.7499C8.70971 9.29987 7.90971 7.40987 7.89971 5.36987C7.89971 4.22987 8.11971 3.12987 8.56971 2.08987C9.00971 1.07987 8.69971 0.549872 8.47971 0.329872C8.24971 0.0998723 7.70971 -0.220128 6.64971 0.219872C2.55971 1.93987 0.0297082 6.03987 0.329708 10.4299C0.629708 14.5599 3.52971 18.0899 7.36971 19.4199C8.28971 19.7399 9.25971 19.9299 10.2597 19.9699C10.4197 19.9799 10.5797 19.9899 10.7397 19.9899C14.0897 19.9899 17.2297 18.4099 19.2097 15.7199C19.8797 14.7899 19.6997 14.1999 19.5297 13.9299Z" fill="#292D32"/>
                            </svg>
                            <svg className={'sun' + (mode === 'dark' ? ' active' : '')} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z" fill="#FDFDFD"/>
                                <path d="M12 22.96C11.45 22.96 11 22.55 11 22V21.92C11 21.37 11.45 20.92 12 20.92C12.55 20.92 13 21.37 13 21.92C13 22.47 12.55 22.96 12 22.96ZM19.14 20.14C18.88 20.14 18.63 20.04 18.43 19.85L18.3 19.72C17.91 19.33 17.91 18.7 18.3 18.31C18.69 17.92 19.32 17.92 19.71 18.31L19.84 18.44C20.23 18.83 20.23 19.46 19.84 19.85C19.65 20.04 19.4 20.14 19.14 20.14ZM4.86 20.14C4.6 20.14 4.35 20.04 4.15 19.85C3.76 19.46 3.76 18.83 4.15 18.44L4.28 18.31C4.67 17.92 5.3 17.92 5.69 18.31C6.08 18.7 6.08 19.33 5.69 19.72L5.56 19.85C5.37 20.04 5.11 20.14 4.86 20.14ZM22 13H21.92C21.37 13 20.92 12.55 20.92 12C20.92 11.45 21.37 11 21.92 11C22.47 11 22.96 11.45 22.96 12C22.96 12.55 22.55 13 22 13ZM2.08 13H2C1.45 13 1 12.55 1 12C1 11.45 1.45 11 2 11C2.55 11 3.04 11.45 3.04 12C3.04 12.55 2.63 13 2.08 13ZM19.01 5.99C18.75 5.99 18.5 5.89 18.3 5.7C17.91 5.31 17.91 4.68 18.3 4.29L18.43 4.16C18.82 3.77 19.45 3.77 19.84 4.16C20.23 4.55 20.23 5.18 19.84 5.57L19.71 5.7C19.52 5.89 19.27 5.99 19.01 5.99ZM4.99 5.99C4.73 5.99 4.48 5.89 4.28 5.7L4.15 5.56C3.76 5.17 3.76 4.54 4.15 4.15C4.54 3.76 5.17 3.76 5.56 4.15L5.69 4.28C6.08 4.67 6.08 5.3 5.69 5.69C5.5 5.89 5.24 5.99 4.99 5.99ZM12 3.04C11.45 3.04 11 2.63 11 2.08V2C11 1.45 11.45 1 12 1C12.55 1 13 1.45 13 2C13 2.55 12.55 3.04 12 3.04Z" fill="#FDFDFD"/>
                            </svg>
                    </div>
                    <Button color={mode === 'dark' ? 'fade' : 'dark'} backgroundFade='right'>Connect Wallet</Button>
                </ButtonWrapper>

                <div className='menu'>
                    <div className='menu-icon' onClick={() => setShowSidebar(true)}>
                        <span className='line-1'></span>
                        <span className='line-2'></span>
                        <span className='line-3'></span>
                    </div>
                </div>

                <div className={"sidemenu-container" + (showSidebar ? ' active' : '')}>
                    <div className="bg" onClick={() => setShowSidebar(false)}></div>
                    <div className="sidemenu">
                        <div className="sidemenu__header">
                            <div className='mode' onClick={() => changeMode()}>
                                <svg className={'moon' + (mode === 'light' ? ' active' : '')} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M19.5297 13.9299C19.3697 13.6599 18.9197 13.2399 17.7997 13.4399C17.1797 13.5499 16.5497 13.5999 15.9197 13.5699C13.5897 13.4699 11.4797 12.3999 10.0097 10.7499C8.70971 9.29987 7.90971 7.40987 7.89971 5.36987C7.89971 4.22987 8.11971 3.12987 8.56971 2.08987C9.00971 1.07987 8.69971 0.549872 8.47971 0.329872C8.24971 0.0998723 7.70971 -0.220128 6.64971 0.219872C2.55971 1.93987 0.0297082 6.03987 0.329708 10.4299C0.629708 14.5599 3.52971 18.0899 7.36971 19.4199C8.28971 19.7399 9.25971 19.9299 10.2597 19.9699C10.4197 19.9799 10.5797 19.9899 10.7397 19.9899C14.0897 19.9899 17.2297 18.4099 19.2097 15.7199C19.8797 14.7899 19.6997 14.1999 19.5297 13.9299Z" fill="#292D32"/>
                                </svg>
                                <svg className={'sun' + (mode === 'dark' ? ' active' : '')} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z" fill="#FDFDFD"/>
                                    <path d="M12 22.96C11.45 22.96 11 22.55 11 22V21.92C11 21.37 11.45 20.92 12 20.92C12.55 20.92 13 21.37 13 21.92C13 22.47 12.55 22.96 12 22.96ZM19.14 20.14C18.88 20.14 18.63 20.04 18.43 19.85L18.3 19.72C17.91 19.33 17.91 18.7 18.3 18.31C18.69 17.92 19.32 17.92 19.71 18.31L19.84 18.44C20.23 18.83 20.23 19.46 19.84 19.85C19.65 20.04 19.4 20.14 19.14 20.14ZM4.86 20.14C4.6 20.14 4.35 20.04 4.15 19.85C3.76 19.46 3.76 18.83 4.15 18.44L4.28 18.31C4.67 17.92 5.3 17.92 5.69 18.31C6.08 18.7 6.08 19.33 5.69 19.72L5.56 19.85C5.37 20.04 5.11 20.14 4.86 20.14ZM22 13H21.92C21.37 13 20.92 12.55 20.92 12C20.92 11.45 21.37 11 21.92 11C22.47 11 22.96 11.45 22.96 12C22.96 12.55 22.55 13 22 13ZM2.08 13H2C1.45 13 1 12.55 1 12C1 11.45 1.45 11 2 11C2.55 11 3.04 11.45 3.04 12C3.04 12.55 2.63 13 2.08 13ZM19.01 5.99C18.75 5.99 18.5 5.89 18.3 5.7C17.91 5.31 17.91 4.68 18.3 4.29L18.43 4.16C18.82 3.77 19.45 3.77 19.84 4.16C20.23 4.55 20.23 5.18 19.84 5.57L19.71 5.7C19.52 5.89 19.27 5.99 19.01 5.99ZM4.99 5.99C4.73 5.99 4.48 5.89 4.28 5.7L4.15 5.56C3.76 5.17 3.76 4.54 4.15 4.15C4.54 3.76 5.17 3.76 5.56 4.15L5.69 4.28C6.08 4.67 6.08 5.3 5.69 5.69C5.5 5.89 5.24 5.99 4.99 5.99ZM12 3.04C11.45 3.04 11 2.63 11 2.08V2C11 1.45 11.45 1 12 1C12.55 1 13 1.45 13 2C13 2.55 12.55 3.04 12 3.04Z" fill="#FDFDFD"/>
                                </svg>
                            </div>

                            <div className='close-icon' onClick={() => setShowSidebar(false)}>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className='sidemenu__menu'>
                            {navLinks.current.map(item =>
                                item.link ?
                                <Link to={item.url} className='navbar__item' onClick={() => setShowSidebar(false)}>{item.name}</Link> :
                                <a href={item.url} className='navbar__item' onClick={() => setShowSidebar(false)}>{item.name}</a>    
                            )}
                        </div>

                        <div className='sidemenu__action'>
                            <Button color={mode === 'dark' ? 'fade' : 'dark'} backgroundFade='right'>Connect Wallet</Button>
                        </div>
                    </div>
                </div>

            </Row>
        </StyledHeader>
    )
}

export default Header