import { createContext, ReactNode, useEffect, useState } from "react"

export const ThemeMode = createContext<{ mode: string, changeMode: () => void | null }>({
    mode: 'dark',
    changeMode: () => null
})

interface Props {
    children: ReactNode;
}

const ThemeModeProvider: React.FC<Props> = ({ children }) => {
    const [mode, setMode] = useState(window.localStorage.getItem('theme') || 'dark')

    useEffect(() => {
        if(!window.localStorage.getItem('theme')) {
            window.localStorage.setItem('theme', 'dark')
        }
    }, [])

    const changeMode = () => {
        const theme = mode === 'dark' ? 'light' : 'dark'
        
        setMode(theme)
        window.localStorage.setItem('theme', theme)
    }

    return (
        <ThemeMode.Provider value={{ mode, changeMode }}>
            {children}
        </ThemeMode.Provider>
    )
}

export default ThemeModeProvider