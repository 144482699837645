import Main from "Templates/Main"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Home from "pages/Home"
import Faq from "pages/Faq"
import Mint from "pages/Mint"

const Routes: React.FC = () => (
    <Router>
        <Main>
            <Switch>
                <Route>
                    <Route path='/' children={<Home />} exact />
                    <Route path='/faq' children={<Faq />} />
                    <Route path='/mint' children={<Mint />} />
                </Route>
            </Switch>
        </Main>
    </Router>
)

export default Routes