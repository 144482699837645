import Button from 'blocks/Button'
import { Row, RowItem, Container } from 'blocks/CommonStyles'
import { Title, Text } from 'blocks/Typography'
import { ReactNode, useContext } from 'react'
import { ThemeMode } from 'context/ThemeModeProvider'
import StyledDescriptionBox from './styles'

interface Props {
    className?: string;
    imgSrc?: string;
    title?: string;
    secondaryTitle?: any;
    children: ReactNode;
    buttonText?: string;
    id?: string;
}

const DescriptionBox: React.FC<Props> = ({
    className,
    imgSrc,
    title,
    secondaryTitle,
    children,
    buttonText,
    id,
}) => {
    const { mode } = useContext(ThemeMode)

    return (
        <StyledDescriptionBox className={className} id={id}>
            <Row>
                <RowItem className='item'>
                    <Container space='x-small'>
                        <Title color='gold'>{title}</Title>
                    </Container>

                    <Container space='x-small'>
                        <Title size='medium'>{secondaryTitle}</Title>
                    </Container>

                    <Container space='small'>
                        <Text className='text'>{children}</Text>
                    </Container>
                    
                    {buttonText && (
                        <Container space='small'>
                            <Button color={mode === 'dark' ? 'fade' : 'dark'}>{buttonText}</Button>
                        </Container>
                    )}
                </RowItem>
                {imgSrc && (
                    <RowItem className='image-desc'>
                        <img src={imgSrc} alt='desc' />
                    </RowItem>
                )}
            </Row>
        </StyledDescriptionBox>
    )
}

DescriptionBox.defaultProps = {
    className: '',
}

export default DescriptionBox