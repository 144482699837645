import SocialMedia from 'components/SocialMedia'
import StyledFooter from './styles'
import { Divider, Row, RowItem, Col, Container } from 'blocks/CommonStyles'
import { Title, Text } from 'blocks/Typography'
import { Link } from 'react-router-dom'
import { SmallDiscords } from 'icons/Discords'
import { SmallTwitter } from 'icons/Twitter'
import { SmallTelegram } from 'icons/Telegrams'
import { SmallInstagram } from 'icons/Instagram'

const Footer: React.FC = () => (
    <StyledFooter>
        <img src="/static/images/footer-bg.png" alt="" className='footer-bg' />

        <Divider space='medium' />

        <SocialMedia />

        <Divider space='medium' />
        
        <Row className='footer__main'>
            <RowItem className='item'>
                {/* <Container space='x-small'> */}
                    <img src='/static/images/logo.svg' alt='logo' />
                {/* </Container> */}
                <Container space='x-small'>
                    <Text size='medium' className='text'>Lorem ipsum dolor sit amet, consectetur <br /> adipiscing elit, sed do eiusmod tempor incididunt <br /> ut labore et dolore magna aliqua. </Text>
                </Container>
                <Container space='x-small' className='social-footer-container'>
                    <Row align='center' className='social-footer'>
                        <div className='small-icon'>
                            <SmallDiscords />
                        </div>
                        <div className='small-icon'>
                            <SmallTwitter />
                        </div>
                        <div className='small-icon'>
                            <SmallTelegram />
                        </div>
                        <div className='small-icon'>
                            <SmallInstagram />
                        </div>
                    </Row>
                </Container>
            </RowItem>
            <RowItem className='item'>
                <Row justify='center' className='links'>
                    <RowItem>
                        <Container space='x-small'>
                            <Title className='title'>Metaplug</Title>
                        </Container>
                        <Container space='x-small'>
                            <a href="#">About</a>
                        </Container>
                        <Container space='x-small'> 
                            <a href="#">Rarity</a>
                        </Container>
                        <Container space='x-small'> 
                            <a href="#">Roadmap</a>
                        </Container>
                        <Container space='x-small'> 
                            <a href="#">Features</a>
                        </Container>
                        <Container space='x-small'> 
                            <a href="#">Team</a>
                        </Container>
                        <Container space='x-small'> 
                            <Link to="/faq">FAQ</Link>
                        </Container>
                    </RowItem>
                    <RowItem>
                        <Container space='x-small'>
                            <Title className='title'>Legal</Title>
                        </Container>
                        <Container space='x-small'> 
                            <a href="#">Terms</a>
                        </Container>
                        <Container space='x-small'> 
                            <a href="#">Privacy policy</a>
                        </Container>
                    </RowItem>
                </Row>
            </RowItem>
        </Row>

        <Divider space='medium' />

        <Row align='center' justify='center' className='copyright'>
            <Text>Metaplug,INC. All rights reserved.</Text>
        </Row>

    </StyledFooter>
)

export default Footer