import DescriptionBox from "components/DescriptionBox"
import Carousel from 'react-elastic-carousel'
import Card from "blocks/Card"
import StyledRarity from './styles'
import { Container } from "blocks/CommonStyles"
import { Text, Title } from "blocks/Typography"

const breakPoint = [
    { width: 400, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 3, itemsToScroll: 2 },
    { width: 1750, itemsToShow: 3 },
  ]

const Rarity: React.FC = () => (
    <StyledRarity>
        <DescriptionBox
            title="Rarity"
            secondaryTitle={<>Rare and Super Unique NFTs <br /> that You’ll love!</>}
            id='rarity'
        >
            <div className="carousel-wrapper">
                <img src='/static/images/bg.png' className="rarity-bg" alt='bg' />
                <Carousel isRTL={false} itemsToShow={3} breakPoints={breakPoint}>
                    <Card space="x-small">
                        <Container className="image-container" space="xx-small">
                            <img src="/static/images/rarity/rarity-yellow.svg" alt="rarity" />
                        </Container>
                        <Container space="xx-small">
                            <Title color="gold" size="small">Name of Artwork</Title>
                        </Container>
                        <Container>
                            <Text className="text">Lorem Ipsum es simplemente el texto de...</Text>
                        </Container>
                    </Card>
                    <Card space="x-small">
                        <Container className="image-container" space="xx-small">
                            <img src="/static/images/rarity/rarity-green.svg" alt="rarity" />
                        </Container>
                        <Container space="xx-small">
                            <Title color="gold" size="small">Name of Artwork</Title>
                        </Container>
                        <Container>
                            <Text className="text">Lorem Ipsum es simplemente el texto de...</Text>
                        </Container>
                    </Card>
                    <Card space="x-small">
                        <Container className="image-container" space="xx-small">
                            <img src="/static/images/rarity/rarity-pink.svg" alt="rarity" />
                        </Container>
                        <Container space="xx-small">
                            <Title color="gold" size="small">Name of Artwork</Title>
                        </Container>
                        <Container>
                            <Text className="text">Lorem Ipsum es simplemente el texto de...</Text>
                        </Container>
                    </Card>
                </Carousel>
            </div>
        </DescriptionBox>
    </StyledRarity>
)

export default Rarity