import { ReactNode } from 'react'
import StyledCard from './styles'

interface Props {
    className?: string;
    children: ReactNode;
    backgroundFade?: 'right' | 'left';
    space?: 'x-small' | 'small' | 'medium' | 'large';
}

const Card: React.FC<Props> = ({ className, children, backgroundFade, space }) => (
    <StyledCard 
        className={`card ${className}`}
        backgroundFade={backgroundFade}
        cardSpace={space}
    >
        {children}
    </StyledCard>
)

Card.defaultProps = {
    className: '',
    backgroundFade: 'left',
    space: 'medium',
}

export default Card