import styled, { css } from 'styled-components'

interface Props {
    show?: boolean;
}

export default styled.div<Props>`
    ${({ theme: { space, radius, color, mode }, show }) => css`
        display: block;
        min-height: 62px;
        overflow: hidden;
        
        .question {
            overflow: hidden;
            background: ${
                show ? 
                    (mode === 'dark' ? color.white : color.black) : 
                    (mode === 'dark' ? '#2B2B2B' : color.gray[30])
            };
            padding: 0 ${space.sm};
            min-height: 62px;
            border-radius: ${show ? `${radius.md} ${radius.md} 0 0` : radius.md};
            transition: .2s;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .icon {
                width: 32px;
                height: 32px;
                position: relative;
    
                &::after, &::before {
                    content: '';
                    background: ${show ? 
                        (mode === 'dark' ? color.dark : color.light) : 
                        (mode === 'dark' ? color.white : color.dark)
                    };
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transition: .2s;
                }
    
                &::after {
                    width: 22px;
                    height: 2px;
                }
    
                &::before {
                    height: 22px;
                    width: 2px;
                    transform: translate(-50%, -50%) ${show ? 'rotate(-90deg)' : ''};
                }
            }
        }

        .text-color {
            font-size: 18px;
            transition: .2s;

            &.bold {
                font-weight: bold;
            }
        }

        .question-text {
            color: ${
                show ? 
                    (mode === 'dark' ? color.dark : color.light) :
                    (mode === 'dark' ?  color.light : color.dark )
            };
        }


        .answer {
            visibility: ${show ? 'visible' : 'hidden'};
            opacity: ${show ? 1 : 0};
            margin-top: ${show ? 0 : 0}px;
            transition: .2s;
            overflow: hidden;
            height: ${show ? 'auto' : 0};
            background: ${mode === 'dark' ? '#2B2B2B' : 'linear-gradient(95.68deg, rgba(216, 216, 216, 0.1) 0%, rgba(232, 232, 232, 0.5) 0.01%)'};
            border-radius: 0 0 ${radius.md} ${radius.md};
            padding: ${show ? space.sm : 0} ${space.sm};
        }
    `}
`