import StyledRoaMap from './styles'
import { ThemeMode } from 'context/ThemeModeProvider'
import { useContext } from 'react'
import { Container, Row, RowItem } from 'blocks/CommonStyles'
import { Text, Title } from 'blocks/Typography'
import Card from 'blocks/Card'

const RoadMap: React.FC = () => {
    const { mode } = useContext(ThemeMode)

    return (
        <StyledRoaMap id='roadmap'>
            <div className='head'>
                <Row align='flex-end'>
                    <div className='logo'>
                        <img src='/static/images/logo.svg' alt='logo' />
                    </div>
                    <RowItem>
                        <Container space='x-small'>
                            <Title color='gold'>Roadmap</Title>
                        </Container>
                        <Container space='x-small'>
                            <Title size='medium'>Metaplug’s Story</Title>
                        </Container>
                        <Container space='x-small'>
                            <Title bold={false}>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</Title>
                        </Container>
                    </RowItem>
                </Row>
            </div>

            <div className='roadmap'>
                <div className='line'></div>
                <Container space='x-large' className='step-container'>
                    <Row justify='space-between'>
                        <Card space='small' className='left-card'>
                            <Container space='normal'>
                                <Row justify='space-between'>
                                    <Title className='card-title' color='gold' size='small'>Step 1: </Title>
                                    <Title className='card-title' color='gold'>10 % - 26 Dec 2021</Title>
                                </Row>
                            </Container>
                            <Title size='small'>Advertising Campaign to gain more attention</Title>

                            <div className="connect-line">
                                <div className="inner-line"></div>
                                <div className="square"></div>
                            </div>
                        </Card>

                        <Card className='right-card'>
                            <Container space='normal'>
                                <Row justify='space-between'>
                                    <Title className='card-title' color='gold' size='small'>Step 2: </Title>
                                    <Title className='card-title' color='gold'>10 % - 26 Dec 2021</Title>
                                </Row>
                            </Container>
                            <Title size='small'>Step 2: Pre-sale release of 2,000 1/1 NFT's</Title>

                            <div className="connect-line">
                                <div className="inner-line"></div>
                                <div className="square"></div>
                            </div>
                        </Card>
                    </Row>
                </Container>

                <Container space='x-large' className='step-container'>
                    <Row justify='space-between'>
                        <Card className='left-card'>
                            <Container space='normal'>
                                <Row justify='space-between'>
                                    <Title className='card-title' color='gold' size='small'>Step 3: </Title>
                                    <Title className='card-title' color='gold'>10 % - 26 Dec 2021</Title>
                                </Row>
                            </Container>
                            <Title size='small'>Huge Marketing Campaign to prepare for Launch involving Big celebrities, billboards and Marketing sites</Title>
                            <div className="connect-line">
                                <div className="inner-line"></div>
                                <div className="square"></div>
                            </div>
                        </Card>

                        <Card className='right-card'>
                            <Container space='normal'>
                                <Row justify='space-between'>
                                    <Title className='card-title' color='gold' size='small'>Step 4: </Title>
                                    <Title className='card-title' color='gold'>10 % - 26 Dec 2021</Title>
                                </Row>
                            </Container>
                            <Title size='small'>Release day Minting of 8,000 1/1 NFT's, reveal who wins Minting day giveaways AFTER SELL OUT!</Title>
                            <div className="connect-line">
                                <div className="inner-line"></div>
                                <div className="square"></div>
                            </div>
                        </Card>
                    </Row>
                </Container>

                <Container space='x-large' className='step-container'>
                    <Row justify='space-between'>
                        <Card className='left-card'>
                            <Container space='normal'>
                                <Row justify='space-between'>
                                    <Title className='card-title' color='gold' size='small'>Step 5: </Title>
                                    <Title className='card-title' color='gold'>10 % - 26 Dec 2021</Title>
                                </Row>
                            </Container>
                            <Title size='small'>Release 2D game and Start Inmate nft information Magazine to teach prison inmates about Crypto/NFT/Metaverse</Title>
                            <div className="connect-line">
                                <div className="inner-line"></div>
                                <div className="square"></div>
                            </div>
                        </Card>

                        <Card className='right-card'>
                            <Container space='normal'>
                                <Row justify='space-between'>
                                    <Title className='card-title' color='gold' size='small'>Step 6: </Title>
                                    <Title className='card-title' color='gold'>10 % - 26 Dec 2021</Title>
                                </Row>
                            </Container>
                            <Title size='small'>Keep up with Monthly giveaways for NFT owners with % of royalties from sales keeping your NFT valuable.</Title>
                            <div className="connect-line">
                                <div className="inner-line"></div>
                                <div className="square"></div>
                            </div>
                        </Card>
                    </Row>
                </Container>

                <Container space='x-large' className='step-container'>
                    <Row justify='center'>
                        <Card className='top'>
                            <Container space='normal'>
                                <Row justify='space-between'>
                                    <Title className='card-title' color='gold' size='small'>Step 7: </Title>
                                    <Title className='card-title' color='gold'>10 % - 26 Dec 2021</Title>
                                </Row>
                            </Container>
                            <Title size='small'>Start development of 3d game.</Title>
                            <div className="connect-line">
                                <div className="inner-line"></div>
                                <div className="square"></div>
                            </div>
                        </Card>
                    </Row>
                </Container>
            </div>

            <img className='roadmap-bg' src='/static/images/bg.png' alt='bg' />
            {/* <img src={`/static/images/road-map-${mode}.png`} alt='road-map' /> */}
        </StyledRoaMap>
    )
}

export default RoadMap