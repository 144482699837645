import styled, { css } from 'styled-components'

// Text
interface TextProps {
    color?: 'dark' | 'light' | 'gold';
    size?: 'small' | 'medium' | 'large' | 'x-large' | 'normal';
    align?: 'center' | 'right' | 'left';
}

const Text = styled.div<TextProps>`
    ${({ theme, color, size, align }) => {
        const calculateSize = () => {
            switch(size) {
                case 'normal': return theme.fs.xs
                case 'small': return theme.fs.xxs
                case 'medium': return theme.fs.md
                case 'large': return theme.fs.lg
                case 'x-large': return theme.fs.xl

                default: return ''
            }
        }

        return css`
            ${color === 'gold' ? 
                `
                    background: -webkit-linear-gradient(#eee, #333);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                ` : `color: ${
                        color === 'light' ? 
                            (theme.mode === 'dark' ? theme.color.light : theme.color.dark) : 
                            (theme.mode === 'light' ? theme.color.dark : theme.color.light)
                    };`
            }
            font-size: ${calculateSize()};
            line-height: 40px;
            text-align: ${align};
        `
    }}
`

Text.defaultProps = {
    color: 'light',
    size: 'normal',
    align: 'left',
}

// Title 
interface TitleProps {
    color?: 'dark' | 'light' | 'gold';
    size?: 'small' | 'medium' | 'large' | 'normal';
    bold?: boolean;
    align?: 'center' | 'right' | 'left';
}

const Title = styled.div<TitleProps>`
    ${({ theme, size, color, bold, align }) => {
        const calculateSize = () => {
            switch(size) {
                case 'normal': return theme.fs.lg
                case 'small': return theme.fs.md
                case 'medium': return theme.fs.xl
                case 'large': return theme.fs.xxxl

                default: return ''
            }
        }

        return css`
            ${color === 'gold' ? 
                ` 
                    background: linear-gradient(#F4D941, #A8803E);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                ` : `color: ${
                    color === 'light' ? 
                    (theme.mode === 'dark' ? theme.color.light : theme.color.black) : 
                    (theme.color.dark)
                };`
            }
            font-size: ${calculateSize()};
            ${bold ? 'font-weight: bold;' : ''}
            text-align: ${align};
        `
    }}
`

Title.defaultProps = {
    color: 'light',
    size: 'normal',
    bold: true,
    align: 'left',
}

export {
    Text,
    Title,
}