import styled, { css } from 'styled-components'

export default styled.div`
    ${({ theme: { space, radius, color, mode, screenSize } }) => css`
        padding: ${space.md};

        .button-icon {
            width: 90px;
            height: 90px;
            border-radius: ${radius.circle};
            background: ${
                mode === 'dark' ? 
                    `linear-gradient(95.68deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.5) 100%)` : 
                    `linear-gradient(95.68deg, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%)`
            };
            backdrop-filter: blur(25px);
            border: 1px solid ${mode === 'dark' ? color.gray[60] : color.white};

            svg {
                & > * {
                    fill: ${mode === 'dark' ? color.light : color.black};
                }
            }

            @media (max-width: ${screenSize.mobile}) {
                width: 60px;
                height: 60px;
            }
        }

        @media (max-width: ${screenSize.mobile}) {
            .item {
                width: 60px;
                max-width: 60px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:not(&:last-child) {
                    margin-right: 0;
                }

                &:not(&:nth-child(2n)) {
                    margin-right: ${space.sm};
                }

                margin-bottom: ${space.sm};
            }
        }
    `}
`

export const StyledSocialMedia = styled.div`
    ${() => css`
    `}
`