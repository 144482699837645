import styled, { css } from 'styled-components'

export default styled.div`
    ${({ theme: { space, screenSize } }) => css`
    
        .image-list {
            padding: ${space.xxl} ${space.md} ${space.md};
        }

        .questions-container {
            padding-bottom: 10em;
            position: relative;

            @media (max-width: ${screenSize.mobile}) {
                padding-bottom: 1em;
            }

            .faq-bg {
                position: absolute;
                z-index: -1;
                right: -50vw;
                top: -300px;
            }
        }

        .image-item {
            margin-right: 5px;
        }

        .image-container {
            img {
                width: 100%;
                margin-left: ${space.sm};
            }
        }
    `}

`   